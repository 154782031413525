import { Component, HostBinding, HostListener, Optional, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Subscription, SubscriptionLike } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { lightboxAnimations } from './lightbox.animation';

@Component({
  selector: 'lightbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [lightboxAnimations.slideLightbox],
  styles: [`::ng-deep lightbox{position:relative;display:block;width:1100px;height:800px;max-width:94vw;max-height:90vh}::ng-deep lightbox gallery{overflow:hidden;margin:0;display:block;width:100%;height:100%;box-shadow:0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12)}::ng-deep .g-backdrop{background-color:rgba(0,0,0,.9)}::ng-deep .fullscreen{width:100%}::ng-deep .fullscreen ::ng-deep lightbox{max-width:unset;max-height:unset;position:fixed;top:0;left:0;bottom:0;right:0;height:100%;width:100%}::ng-deep .g-overlay{margin:auto}@media only screen and (max-width:480px){::ng-deep .g-overlay{width:100%}::ng-deep .g-overlay ::ng-deep lightbox{max-width:unset;max-height:unset;position:fixed;top:0;left:0;bottom:0;right:0;height:100%;width:100%}}::ng-deep .cdk-global-overlay-wrapper,::ng-deep .cdk-overlay-container{pointer-events:none;top:0;left:0;height:100%;width:100%}::ng-deep .cdk-overlay-container{position:fixed;z-index:1000}::ng-deep .cdk-global-overlay-wrapper{display:flex;position:absolute;z-index:1000}::ng-deep .cdk-overlay-pane{position:absolute;pointer-events:auto;box-sizing:border-box;z-index:1000}::ng-deep .cdk-overlay-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1000;pointer-events:auto;-webkit-tap-highlight-color:transparent;transition:opacity .4s cubic-bezier(.25,.8,.25,1);opacity:0}::ng-deep .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:.48}::ng-deep .cdk-overlay-dark-backdrop{background:rgba(0,0,0,.6)}::ng-deep .cdk-overlay-transparent-backdrop{background:0 0}::ng-deep .cdk-global-scrollblock{position:fixed;width:100%;overflow-y:scroll}::ng-deep .g-btn-close{position:absolute;right:.9em;top:.9em;z-index:60;cursor:pointer;width:20px;height:20px}@media only screen and (max-width:480px){::ng-deep .g-btn-close{right:.7em;top:.7em}}`],
  template: `
    <gallery [id]="id" [destroyRef]="false" [skipInitConfig]="true">
      <i class="g-btn-close" aria-label="Close" (click)="overlayRef.detach()"
         [innerHTML]="sanitizer.bypassSecurityTrustHtml(closeIcon)"></i>
    </gallery>
  `
})
export class LightboxComponent implements OnDestroy {

  /** Gallery ref id */
  id: string;

  /** Overlay ref to close the lightbox */
  overlayRef: OverlayRef;

  /** close button svg data */
  closeIcon: string;

  /** Subscription to changes in the user's location. */
  private _locationChange$: SubscriptionLike = Subscription.EMPTY;

  /** Use slide animation on opening and closing the lightbox */
  @HostBinding('@slideLightbox') slideAnimation;

  /** Dispose the overlay when exit animation is done */
  @HostListener('@slideLightbox.done', ['$event']) onMouseLeave(e) {
    if (e.toState === 'void') {
      this.overlayRef.dispose();
    }
  }

  constructor(public sanitizer: DomSanitizer, @Optional() location: Location) {
    // Close the Lightbox when the location changes
    if (location) {
      this._locationChange$ = location.subscribe(() => this.overlayRef.detach());
    }
  }

  ngOnDestroy() {
    this._locationChange$.unsubscribe();
  }

}
